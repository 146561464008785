html {
  width: calc(100vw);
  height: calc(100vh);
  margin: 0px;
}

body {
  margin: 0px
}

.general-container {
  height: 100vh;
  width: 100vw;
}

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  }

@media (min-width: 520px) {
  html {
    width: calc(100vw);
    height: calc(100vh);
    margin: 0px;
  }
  body {
    overflow: hidden;
    transform: scale(1.03);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    background-image: url('https://d221xxk5mfaxk5.cloudfront.net/app/backgroundimage-BkdF77mLu-L.png');
  }

  .general-container {
    display: grid;
    height: 100vh;
    place-items: center;
    margin: 0;
  }

  main {
    width: 520px;
    height: 90vh;
    border-radius: 10px;
    box-shadow: 0 0px 25px rgba(0, 0, 0, 0.15);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.horizontalCenter {
  text-align: center;
  display: block;
  margin: auto;
}

.verticalCenter {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.generalContainer {
  width: 100vw;
  height: calc(100vh - 70px);
}

button:disabled,
button[disabled] {
  background-color: #a1a1a1 !important;
}

button {
  border: none;
  outline: none;
}
