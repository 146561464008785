*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
main {
  background-color: #F2F2F2;
  scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

.modalItemContainer .modal-wrapper .containerProducts::-webkit-scrollbar {
  display: none;
}
main::-webkit-scrollbar {
  display: none;
}
#root::-webkit-scrollbar {
  display: none;
}