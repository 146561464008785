:root {
  --background: #f6f6f6;
  --background-gray: #f2f2f2;
  --background-loader: #f4f4f4;
	--background-search: #FAFBFC;
  --button_green3: #00d27c;
  --discounts: #e30937;
  --red_chiper: #FF0136;
  --strokes: #979797;
  --text: #2B2845;
  --text-title: #3e3e3e;
  --white: #ffffff;
}
