@font-face {
	font-family: "avenir";
	src: local("avenir"),
		url("../assets/fonts/avenir/AvenirNextLTPro-Regular.eot");
	src: local("avenir"),
		url("../assets/fonts/avenir/AvenirNextLTPro-Regular.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/avenir/AvenirNextLTPro-Regular.woff2") format("woff2"),
		url("../assets/fonts/avenir/AvenirNextLTPro-Regular.woff") format("woff"),
		url("../assets/fonts/avenir/AvenirNextLTPro-Regular.ttf") format("truetype"),
		url("../assets/fonts/avenir/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "avenir";
	src: url("../assets/fonts/avenir/Avenir-Medium.eot");
	src: url("../assets/fonts/avenir/Avenir-Medium.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/avenir/Avenir-Medium.woff2") format("woff2"),
		url("../assets/fonts/avenir/Avenir-Medium.woff") format("woff"),
		url("../assets/fonts/avenir/Avenir-Medium.ttf") format("truetype"),
		url("../assets/fonts/avenir/Avenir-Medium.svg#Avenir-Medium") format("svg");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "avenir";
	src: url("../assets/fonts/avenir/AvenirLTStd-Black.eot");
	src: url("../assets/fonts/avenir/AvenirLTStd-Black.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/avenir/AvenirLTStd-Black.woff2") format("woff2"),
		url("../assets/fonts/avenir/AvenirLTStd-Black.woff") format("woff"),
		url("../assets/fonts/avenir/AvenirLTStd-Black.ttf") format("truetype"),
		url("../assets/fonts/avenir/AvenirLTStd-Black.svg#AvenirLTStd-Black") format("svg");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "avenir";
	src: url("../assets/fonts/avenir/AvenirNextLTPro-BoldCn.eot");
	src: url("../assets/fonts/avenir/AvenirNextLTPro-BoldCn.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/avenir/AvenirNextLTPro-BoldCn.woff2") format("woff2"),
		url("../assets/fonts/avenir/AvenirNextLTPro-BoldCn.woff") format("woff"),
		url("../assets/fonts/avenir/AvenirNextLTPro-BoldCn.ttf") format("truetype"),
		url("../assets/fonts/avenir/AvenirNextLTPro-BoldCn.svg#AvenirNextLTPro-BoldCn") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "avenir";
	src: url("../assets/fonts/avenir/Avenir-Heavy.eot");
	src: url("../assets/fonts/avenir/Avenir-Heavy.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/avenir/Avenir-Heavy.woff2") format("woff2"),
		url("../assets/fonts/avenir/Avenir-Heavy.woff") format("woff"),
		url("../assets/fonts/avenir/Avenir-Heavy.ttf") format("truetype"),
		url("../assets/fonts/avenir/Avenir-Heavy.svg#Avenir-Heavy") format("svg");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Avenir Book";
	src: url("../assets/fonts/avenir/Avenir-Book.eot");
	src: url("../assets/fonts/avenir/Avenir-Book.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/avenir/Avenir-Book.woff2") format("woff2"),
		url("../assets/fonts/avenir/Avenir-Book.woff") format("woff"),
		url("../assets/fonts/avenir/Avenir-Book.ttf") format("truetype"),
		url("../assets/fonts/avenir/Avenir-Book.svg#Avenir-Book") format("svg");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poetsen One";
	src: local("Poetsen One"),
		url("../assets/fonts/poetsen-one/PoetsenOne-Regular.eot");
	src: local("Poetsen One"),
		url("../assets/fonts/poetsen-one/PoetsenOne-Regular.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/poetsen-one/PoetsenOne-Regular.woff2") format("woff2"),
		url("../assets/fonts/poetsen-one/PoetsenOne-Regular.woff") format("woff"),
		url("../assets/fonts/poetsen-one/PoetsenOne-Regular.ttf") format("truetype"),
		url("../assets/fonts/poetsen-one/PoetsenOne-Regular.svg#PoetsenOne-Regular") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'causten round black';
	src: url('../assets/fonts/causten-round/causten_round_black-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_black-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round black oblique';
	src: url('../assets/fonts/causten-round/causten_round_black_oblique-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_black_oblique-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round extra bold';
	src: url('../assets/fonts/causten-round/causten_round_extra_bold-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_extra_bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten_round extra bold oblique';
	src: url('../assets/fonts/causten-round/causten_round_extra_bold_oblique-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_extra_bold_oblique-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round bold';
	src: url('../assets/fonts/causten-round/causten_round_bold-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round bold oblique';
	src: url('../assets/fonts/causten-round/causten_round_bold_oblique-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_bold_oblique-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round semi bold';
	src: url('../assets/fonts/causten-round/causten_round_semi_bold-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_semi_bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round semi bold oblique';
	src: url('../assets/fonts/causten-round/causten_round_semi_bold_oblique-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_semi_bold_oblique-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round medium';
	src: url('../assets/fonts/causten-round/causten_round_medium-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round medium oblique';
	src: url('../assets/fonts/causten-round/causten_round_medium_oblique-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_medium_oblique-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round';
	src: url('../assets/fonts/causten-round/causten_round_regular-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'causten round oblique';
	src: url('../assets/fonts/causten-round/causten_round_regular_oblique-webfont.woff2') format('woff2'),
		url('../assets/fonts/causten-round/causten_round_regular_oblique-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

* {
	font-family: "causten round";
	font-display: auto;
	font-display: swap;
}

@media screen and (max-width: 992px) {
	* {
		font-size: 14px;
	}
}
