.container {
  align-items: center;
  justify-content: center;
}

.container div {
  margin-top: 24px;
  max-width: 300px;
  font-family: Causten Round;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  color: var(--text);
}
